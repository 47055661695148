import React from "react"
import Layout from "../components/common/Layout"
import CareersLink from "../components/common/CareersLink"
import ArrowXReverse from "../components/common/ArrowXReverse"
import Footer from "../components/common/Footer"
import NewsList from "../components/common/NewsList"

let page = "news"
let headTtl = "NEWS<span class='p-common-kv__head-ttl-min'>&</span>PRESS"

export default function NewsDetail({ pageContext }) {
  const { title, publishedAt, category } = pageContext.post
  const edges = pageContext.posts
  const body = pageContext.post.body.childMarkdownRemark.html

  let type = category[0].toUpperCase()

  return (
    <Layout>
      <div id="page">
        <div id="news" className="js-page">
          <main id="main">
            <div id="contents" className="contents">
              <section className={`p-common-kv p-${page}-kv`}>
                <div className="p-common-kv__wrapper">
                  <div className="p-common-kv__head">
                    <h1 className="p-common-kv__head-ttl">
                      <div dangerouslySetInnerHTML={{ __html: headTtl }} />
                    </h1>
                  </div>
                </div>
              </section>
              <section className="p-news__detail-main">
                <div className="p-news__detail-main-wrapper">
                  <div className="p-news__detail-main-header">
                    <span className="p-news__detail-main-header-time">
                      {publishedAt}
                    </span>
                    <span className="p-news__detail-main-header-type">
                      {type}
                    </span>
                  </div>
                  <article className="p-news__detail-main-body">
                    <h2 className="p-news__detail-main-body-ttl">{title}</h2>
                    {/*<img src={image.file.url} className="post-image" alt="post-cover" />*/}
                    <div className="p-news__detail-main-body-content">
                      <div dangerouslySetInnerHTML={{ __html: body }} />
                    </div>
                  </article>
                  <div className="p-news__detail-main-footer">
                    <a
                      className="js-hover p-news__detail-main-footer-link"
                      data-hover-in-time="600"
                      href="/news/"
                    >
                      <div className="c-link__main p-news__detail-main-footer-link-wrapper">
                        <span className="p-news__detail-main-footer-link-span">
                          <span className="c-link__main-inner">BACK</span>
                          <ArrowXReverse />
                        </span>
                      </div>
                    </a>
                  </div>
                </div>
              </section>
              <section className="p-common-news p-news__detail-news">
                <div className="p-common-news__wrapper">
                  <div className="p-common-news__body">
                    {edges.map(edge => (
                      <NewsList key={edge.node.slug} news={edge.node} />
                    ))}
                  </div>
                </div>
              </section>
              <CareersLink />
            </div>
          </main>
          <Footer />
        </div>
      </div>
    </Layout>
  )
}
